
import RcTableViewModel from "../../../../shared/table/RcTableViewModel";

import ListViewUtils from "./ListViewUtils";

import ContentUtils from '@/utils/ContentUtils.js';
import StringUtils from '@/utils/StringUtils.js';
import CompanyMap from "@/domain/model/company/CompanyMap.js";
import CompanyListFilter from "@/domain/model/company/CompanyListFilter.js";
import RcResponseEvent from "../../../../../domain/model/events/RcResponseEvent";

export default class ListViewModel extends RcTableViewModel {

  constructor(panel) {
    super(panel);
    this._searchText = panel.$route.query.search ? panel.$route.query.search : "";
    this._total = 0;
    this._itemList = [];
    this._itemMap = null;
    this._pages = [];
  }

  start() {
    super.start();
  }

  isAdmin() {
    return super.isAdmin();
  }

  withSearchText(valueData) {
    this._searchText = valueData["value"]
    return this;
  }

  searchText() {
    return this._searchText;
  }

  withValue(valueData) {
    super.withValue(valueData, "company");
  }

  withButtonPressed(buttonData) {
    super.withButtonPressed(buttonData);
    if (!buttonData) {
      return;
    }
  }

  buildRowData(tableData) {
    var utils = new ListViewUtils(this, tableData);
    utils.start();
    return this;
  }

  loadItems(forceUpdate = true) {
    super.loadItems();
    var query = this.panel().$route.query;
    this.panel().displayProgress("Loading companies");
    var time = query["time"];
    if (!isNaN(time)) {
      time = "(" + time + ")";
    }
    if (StringUtils.isEmpty(time)) {
      time = "last30Days";
    }

    var context = {};
    context["model"] = this;
    context["startTime"] = new Date();

    var filter = new CompanyListFilter();
    filter
      .withState(query["status"] ? query["status"] : "active")
      .withPagination(this.pageSize(query, "company"), query["page"] ? (query["page"] - 1) : 0)
      .done();

    if (!StringUtils.isEmpty(this._searchText)) {
      filter
        .withSearchText(this._searchText)
        .done()
    }
    context["sortBy"] = query["sortBy"] ? query["sortBy"] : "nameAz";
    filter
      .withSortBy(context["sortBy"])
      .done();

    if (!StringUtils.isEmpty(query["paying"])) {
      filter
        .withPaying(query["paying"])
        .done();
    }
    if (!StringUtils.isEmpty(query["card"])) {
      filter
        .withCardSet(query["card"])
        .done();
    }
    if (!StringUtils.isEmpty(query["email"])) {
      filter
        .withEmailInvoice(query["email"])
        .done();
    }
    if (!StringUtils.isEmpty(query["charge"])) {
      filter
        .withChargeInvoice(query["charge"])
        .done();
    }
    if (!StringUtils.isEmpty(query["feature"])) {
      filter
        .withFeature(query["feature"])
        .done();
    }
    if (!StringUtils.isEmpty(query["promo"])) {
      filter
        .withPromoCode(query["promo"])
        .done();
    }
    if (!StringUtils.isEmpty(StringUtils.toString(query["qbcustomerId"]))) {
      filter
        .withQbCustomerId(query["qbcustomerId"])
        .done();
    }

    context["fetched"] = true;
    context["filter"] = JSON.stringify(filter);
    let filterKey = context["filter"];
    let subdomain = this.domain().inspections();

    if (!subdomain.hasFilter(filterKey) || forceUpdate) {
      var event = this.domain().events().companys().list(filter);
      if (this.isAdmin()) {
        event.with("signatures", true);
        event.with("includeInvoiceSettings", true);
        event.with("includeAddresses", true);
      }
      event.send(this.loadedItemsListener, context);
    } else {
      context["fetched"] = false;
      let data = subdomain.getFilter(filterKey);
      let size = subdomain.getFilterSize(filterKey);
      let response = new RcResponseEvent(this.domain(), {});
      let payload = response.payload();
      payload.withCount(size);
      payload.put(CompanyMap.MODEL_NAME, data);
      response.withPayload(payload);
      this.loadedItemsListener(response, context);
    }
  }

  loadedItemsListener(responseEvent, context) {
    var model = context.model;
    context["loadTime"] = new Date();
    if (responseEvent.error()) {
      model.withError(responseEvent.error());
    } else {
      var payload = responseEvent.payload();
      model._total = payload.count();
      var payloadMap = payload.valuesFor(CompanyMap.MODEL_NAME);
      var companyMap = new CompanyMap(model.domain(), payloadMap);
      if (!context["fetched"]) {
        companyMap = companyMap.find();
      }
      var query = model.panel().$route.query;
      model._pages = ContentUtils.PAGE_INDEXES(model._total, model.pageSize(query, "company"))
      model.update(context, companyMap);
      context["sortTime"] = new Date();
      model.panel().delayedClearAndBuildTable();
      model.domain().inspections().putFilter(context["filter"], companyMap, payload.count());
    }

    delete context.model;
    context["renderTime"] = new Date();
    model.log().info("Client Performance {0}", [JSON.stringify(context, null, 2)]);
  }

  update(context, companyMap) {
    let model = context.model;
    let sortBy = context.sortBy;
    var companyList = companyMap.sortBy(sortBy);
    model._itemList = companyList;
  }

  totalItems() {
    return this._total;
  }

  items() {
    return this._itemList;
  }

  pages() {
    return this._pages;
  }

}